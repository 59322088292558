import axios from 'axios';

import {
  SEARCH_DONE, SEARCH_FAIL
} from './types';
/*export default axios.create({
  baseURL: 'https://gnews.io/api/v4/' //'https://newsapi.org/v2/'
});*/
//import { request } from 'express';
//var axios = require('axios');
const key='AIzaSyAfFfw0SQTVSE0wPXrCsvzvicB4OG8sNhk';


export const find = ({search, latitude, longitude}) => dispatch =>{
/*var config = {
  method: 'get',
  //baseURL:'https://maps.googleapis.com/maps/api/place/findplacefromtext/',
  //url: `json?input=Museum of Contemporary Art Australia&inputtype=textquery&fields=formatted_address,name,rating,opening_hours,geometry&key=${key}`,
  headers: {
    //'access-control-allow-origin' : '*',
    //'access-control-allow-methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //"origin": "*",
    //'access-control-request-headers': {'access-control-allow-methods':'*','access-control-allow-origin':'*'},
    crossdomain: true,
    'Context-Type': 'application/json',
    mode: 'no-cors',
  },
  //origin:['*'],
  //'access-control-request-headers': {'access-control-allow-methods':'*','access-control-allow-origin':'*'},
  //'access-control-allow-origin':'*',
  //'access-control-allow-methods':'*',
  crossdomain: true,
  'Context-Type': 'application/json',


  //mode: 'no-cors',
};*/
console.log("find:");


//&locationbias=circle:2000@47.6918452,-122.2226413
  
var lb = "";
if (latitude!="" || longitude!=""){
  lb=`&location=${latitude},${longitude}`
}
console.log(lb);
axios.get(`api/place/textsearch/json?query=${search}${lb}&key=${key}`).then(res => 
    dispatch({type:SEARCH_DONE, payload: res.data})).catch(error => dispatch({type:SEARCH_FAIL}));
}
