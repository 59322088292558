import {combineReducers} from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import findReducer from './findReducer';


export default combineReducers({
  error: errorReducer,
  auth:authReducer,
  find:findReducer,
})