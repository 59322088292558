export const fpUseStyles  = theme => ({
  //const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //paddingBottom:'1rem'
    //height:'100%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  snackbar:{
    position: 'relative',
    width:'100%',
    transform:'none',
    left:0,
    top:0
  },
  placeholder:{
    minHeight:'5rem',
  }
});
