import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import FindPartner from './FindPartner';
import reportWebVitals from './reportWebVitals';
import CrossStorageClient from './client'
//import { loadUser } from './actions/authActions';
//import store from './store';

var storage = new CrossStorageClient('http://localhost:3000/hub.html');

storage.onConnect().then(function() {
  console.log('first');
  return storage.get('token');
}).then(function(res) {
  console.log(res.length);
    if (res && res.length > 0){
      var t = res[0];
  if (t)localStorage.setItem('token',t);/*store.dispatch(loadUser);*/console.log(t);}
}).catch(function(err) {
  // Handle error
});
//module.exports = {
  //CrossStorageClient: require('./client.js'),
  //CrossStorageHub:    require('./hub.js')
//};
ReactDOM.render(
  <React.StrictMode>
    <FindPartner />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
