
import React, { Component } from 'react';
import { Container } from '@material-ui/core';


import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';


import { fpUseStyles } from '../FindPartnerStyles';
import Box from '@material-ui/core/Box'
import {find} from '../actions/find'

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SearchResults from './SearchResults';

class MainContainer extends Component {
  state = {
    search:'',
    latitude:'',
    longitude: '',
    isShowSearch:false,
    results:[],
  };
  static propTypes = {
    isSearchDone:PropTypes.bool,
    find: PropTypes.func.isRequired,
    results: PropTypes.array,
  };
  componentDidUpdate(prevProps){
    const {isSearchDone, results} = this.props;
    console.log('ComponentDidUpdate');
    //console.log(JSON.stringify(prevProps.results));
    //console.log(prevProps.isSearchDone);    
    if (isSearchDone !== prevProps.isSearchDone){
      console.log(JSON.stringify(results));
      console.log(isSearchDone);
      this.setState({isShowSearch: isSearchDone, results:results});
    }
  }
  onChange = e =>{
    this.setState({[e.target.id]:e.target.value, open:false});
  }
  onSubmit = e =>{
    console.log("onSubmit!");
    e.preventDefault();
    const {search , latitude, longitude} = this.state;
    this.props.find({search , latitude, longitude});
    //const user = {email, password};
    //this.props.signin(user);
    console.log(`onSubmit end! {search} {latitude}`);
    
    
  }
  searchResult(){
     if (this.props.isSearchDone){
      console.log('in search done');
      console.log(JSON.stringify(this.props.results));
      return <SearchResults></SearchResults>
     }
     return<div>Nothing</div>;
  }
  render() {
    const {classes} = this.props;
    const sr = this.searchResult();
  return (
      <Container m={30}>
        <div className={classes.paper}>
        <form className={classes.form} noValidate onSubmit = {this.onSubmit}> {/*className={classes.form}*/}


        <Grid container spacing={2} >
          <Grid item xs={12} md={4}>
           <TextField id="search" label="Search" type="search" variant="outlined" fullWidth onChange={this.onChange}/>
          </Grid>
          <Grid item xs={6} md={4}>
          <TextField id="latitude" label="Latitude" type="number" inputProps={{step:"0.0001",style:{textAlign:"right"}}} variant="outlined" fullWidth onChange={this.onChange}/>
          </Grid>          
          <Grid item xs={6} md={4}>
          <TextField id="longitude" label="Longitude" type="number" inputProps={{step:"0.0001",style:{textAlign:"right"}}} variant="outlined" onChange={this.onChange}fullWidth/>
          </Grid>          


        </Grid>
        <Box display="flex" justifyContent="center">
           <Button
            type="submit"
            //fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            //onClick={this.handleSubmitButtonClick}
            //onClick = {this.toggle}
            mx='auto'
          >
            Find
          </Button>
        </Box>


         </form>
         </div>
         {
         sr 
         }
        </Container>
    );
  }
}
const mapStateToProp = state =>({
  isSearchDone: state.find.isSearchDone,
  results: state.find.results
})

export default connect(mapStateToProp, {find})(withStyles(fpUseStyles)(MainContainer));
//export default MainContainer;