import {useEffect} from 'react'
import { MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
//import Header from '../../common/src/Header'
import Header from './Header'
import store from './store';
import {Provider} from 'react-redux';
import BVSignUp from './components/SignUpModule';
import BVSignIn from './components/SignInModule';
import { loadUser } from './actions/authActions';
import MainContainer from './components/MainContainer';
import { fpUseStyles } from './FindPartnerStyles';
import { withStyles } from '@material-ui/styles';

function FindPartner({props}) {

 

  //else msg = 'Learn React + null';
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#F44336'
      }
    }
  });

  useEffect(() => {
    // action here
    console.log('App::componentDidMount going to load user.');
    store.dispatch(loadUser());
}, []); // checks for changes in the values in this array

  return (
    <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Header title='Find Partner'/>
      <BrowserRouter>
      <Switch>
      <Route exact path="/" >
        <MainContainer/>

      </Route>
      <Route exact path="/sign-in">
            <BVSignIn/>
          </Route>
          <Route exact path="/sign-up">
            <BVSignUp/>
          </Route>
        <Route exact path="/1">
        

      </Route>
      </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
    </Provider>

  );
}

export default FindPartner;
