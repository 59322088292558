import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {connect} from 'react-redux';
import { find } from '../actions/find';

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flexGrow: 1,
  },
  card1: {
    display: 'flex',
    flexGrow: 1,
  },
  cardGrowCol: {
    display: 'flex',
    flexGrow: 1,
    flexDirection:'column',
    alignItems:'stretch'
  },
  cardText: {
    flexGrow: 1,
    width:'70%',
  },
  cardMedia: {
    width: '30%',
  },
}));
const SearchResults = ({results}) => {
  //const [page, setPage] = useState(1);
  //const [categorySourceUrl, setCategorySourceUrl] = useState(`top-headlines?country=us&topic=technology&sources=&q=`);
  const classes = useStyles();
  //const { post } = props;

  /*const handleCategorySourceSearch = categorySourceUrl => {
    setPage(1);
    setCategorySourceUrl(categorySourceUrl);
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };
*/
  /*useEffect(() => {
    if (categorySourceUrl) {
      const url = `${categorySourceUrl}`;
      setTopNews(url, page);
     //getNewsSource();
    }

    return () => {
      clearTopNews();
    };
    // eslint-disable-next-line
  }, [categorySourceUrl, page]);
*/
console.log('searchResults');
console.log(results.length);
  return (
    <Fragment>
        {results && results.length != 0 && (
        <Grid container spacing={4} alignItems="stretch">
          {results.map((item, i) => (
              <Grid item className={classes.card} xs={12}  direction="column" >
                <CardActionArea className={classes.cardGrowCol} alignContent='stretch' direction="column" component="a" direction="column" href={item.url} target='_blank'>
                  <Card className={classes.card1} direction="row">
                    <div className={classes.cardText}>
                      <CardContent className={classes.cardDetails}>
                        <Typography component="h2" variant="h5">
                          {item.name}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                          {item.formatted_address}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                          {item.rating}
                        </Typography>
                        
                        <Typography variant="subtitle1" color="primary">
                          Continue reading...
                        </Typography>
                        
                      </CardContent>
                    </div>
                    {/*
                      <CardMedia className={classes.cardMedia} xs={4} image={item.icon} title={item.imageTitle}/>
                    */}
                  </Card>
                </CardActionArea>
              </Grid>
          ))}
        </Grid>
        )}
{/*
      {news.newsItemsTotal !== null && !news.newsLoading ? (
        <Row className='py-3'>
          <Col xs={12} sm={12}>
            <p className='h5  text-center'>{news.newsItemsTotal} News Result(s)</p>
          </Col>
        </Row>
      ) : null}

      {news.newsItemsTotal !== null && (
        <Row className='justify-content-md-center mb-4'>
          {news.newsItems.map((item, i) => (
            <NewsItem key={i} item={item} theme={news.theme} />
          ))}
        </Row>
      )}

      {news.newsLoading && (
        <Row className='justify-content-md-center py-4'>
          <Col xs={12} sm={8} className='text-center'>
            <Spinner animation='border' size='lg' />
          </Col>
        </Row>
      )}

      {news.newsItems.length < news.newsItemsTotal && !news.newsLoading && (
        <Row className='justify-content-md-center py-2'>
          <Col xs={12} sm={12} className='text-center'>
            <Button className='py-2' variant={news.theme} onClick={() => handleLoadMore()}>
              Load More
            </Button>
          </Col>
        </Row>
      )}*/}
    </Fragment>
  );
};
const mapStateToProps = state => ({
  results: state.find.results
});

export default connect(mapStateToProps,{find})(SearchResults);

