import {
  SEARCH_DONE, SEARCH_FAIL, SEARCH_IN_PROGRESS
} from '../actions/types';

const initialState = {
  isSearchDone: false, 
};

export default function findReducer(state = initialState, action){
  switch (action.type){
    case SEARCH_DONE:
      console.log("SEARCH_DONE");
      //console.log(JSON.stringify(JSON.parse(action.payload).results));
      return{
        ...state,
        ...JSON.parse(action.payload),
        isSearchDone: true
      };
    case SEARCH_IN_PROGRESS:
    case SEARCH_FAIL:
        return{
          ...state,
          results:[],
          isSearchDone: false
        };
    
    default:
      return state;

  }
}